import axios from 'axios'
import { Toast } from 'vant';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
  headers: {
    Accept: 'application/json;version=1.0.0'
  }
})


service.interceptors.request.use(
  config => {
    return config
  },
  error => {

    console.log(error)
    return Promise.reject(error)
  }
)


service.interceptors.response.use(

  response => {
    const { data } = response
    return data

    //   const { errMsg, statusCode, data } = response

    // if ([2000].includes(data?.errcode)) {
    //   return Promise.reject(response)
    // }

    // if (statusCode == 422) {
    //   const { errors, errcode } = data
    //   for (const key in errors) {
    //     uni.showToast({
    //       title: errors[key][0],
    //       icon: 'none',
    //       duration: 1500
    //     })
    //     break
    //   }
    //   return Promise.reject(response)
    // } else if (statusCode == 401) {
    //   return Promise.reject(response)
    // }
    // uni.showToast({
    //   title: errMsg,
    //   icon: 'none',
    //   duration: 1500
    // })
    // return Promise.reject(response)


  },
  error => {
    const { status, data } = error.response


    if ([2000].includes(data?.errcode)) {
      return Promise.reject(data)
    }

    if (status == 422) {
      const { errors } = data
      for (const key in errors) {
        Toast(errors[key][0])
        break
      }
      return Promise.reject(data)
    } else if (status == 401) {
      return Promise.reject(data)
    }

    Toast(data.message)
    return Promise.reject(data)
  }
)

export default service