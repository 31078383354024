import Vue from 'vue'
import Vant,{Lazyload } from 'vant';
import 'vant/lib/index.css';

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'windi.css'
import 'lib-flexible'

import http from '@/utils/request'
Vue.use(Vant);
Vue.use(Lazyload);
Vue.prototype.$api = http

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
